import React from 'react';
import { ChatProvider } from './context/chatContext';
import AppRouter from './router/Approuter';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

const App = () => {
  return (
    <ChatProvider>
       <BrowserRouter>
       <Toaster
  position="top-center"
  reverseOrder={false}
/>
      <AppRouter />
      </BrowserRouter>
    </ChatProvider> 
  );
};
export default App;
