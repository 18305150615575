import React, { useState, useEffect } from 'react';

const FilterModal = ({
  filterOptions,
  onApplyFilters,
  setFilter,
  selectedTab,
  setSelectedTab,
  initialSelectedFilters,
  onClearAll
}) => {
  const [selectedFilters, setSelectedFilters] = useState(initialSelectedFilters);
  const [dateTimeRange, setDateTimeRange] = useState({
    date: '',
    startTime: '',
    endTime: ''
  });
  useEffect(() => {
    setSelectedFilters(initialSelectedFilters);

    if (initialSelectedFilters.timestamp.date!=="") {
        setDateTimeRange({
          date: initialSelectedFilters.timestamp.date || '',
          startTime: initialSelectedFilters.timestamp.startTime || '',
          endTime: initialSelectedFilters.timestamp.endTime || ''
        });
      }
  }, [initialSelectedFilters]);

  const handleFilterChange = (filter, value) => {
    if (filter === 'timestamp') {
        setSelectedFilters((prevState) => ({
            ...prevState,
            timestamp:dateTimeRange,
          }));
      // Handle Timestamp filtering separately
      return;
    }
    
    setSelectedFilters((prevState) => ({
      ...prevState,
      [filter]: [value],
    }));
  };

  const handleDateTimeChange = (field, value) => {



    const updatedDateTimeRange = { ...dateTimeRange, [field]: value };
    setDateTimeRange(updatedDateTimeRange); // Update dateTimeRange state
  
    setSelectedFilters((prevState) => ({
      ...prevState,
      timestamp: updatedDateTimeRange,
    }));
  };

  const handleApplyFilters = () => {
    onApplyFilters(selectedFilters);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleCloseModal = () => {
    setFilter(false);
  };

  const renderFilterContent = () => {
    if (selectedTab === 'timestamp') {
      return (
        <div className="space-y-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Date</label>
            <div className="flex gap-2">
              <input
                type="date"
                value={dateTimeRange.date}
                onChange={(e) => handleDateTimeChange('date', e.target.value)}
                className="block w-[80%] px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Time</label>
            <div className="flex gap-2 ">
                <div className='flex flex-col gap-1 w-[50%]'>
                    <label>To</label>
              <input
                type="time"
                value={dateTimeRange.startTime}
                disabled={dateTimeRange.date === ""}
                onChange={(e) => handleDateTimeChange('startTime', e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
              </div>
              <div className='flex flex-col gap-1 w-[50%]'>
                <label>from</label>
              <input
                type="time"
                disabled={dateTimeRange.date === ""}
                value={dateTimeRange.endTime}
                onChange={(e) => handleDateTimeChange('endTime', e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="space-y-2 overflow-y-auto h-[18vh] max-h-[18vh]">
        {filterOptions[selectedTab]?.map((option) => (
          <div key={option} className="flex items-center">
            <input
              type="radio"
              id={`${selectedTab}-${option}`}
              checked={selectedFilters[selectedTab].includes(option)}
              onChange={() => handleFilterChange(selectedTab, option)}
              className="mr-2 focus:ring-blue-500 focus:ring-2"
            />
            <label
              htmlFor={`${selectedTab}-${option}`}
              className="text-gray-700 cursor-pointer"
            >
              {option}
            </label>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-end bg-black bg-opacity-50">
      <div className="bg-white p-6 mr-6 mb-4 rounded-lg shadow-lg w-full max-w-[30%]">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Filters</h3>
          <button
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            onClick={onClearAll}
          >
           <span className='text-[14px] text-[red]'> clear all</span>
          </button>
        </div>
        <div className="flex gap-4">
          <div className="border-r pr-4 min-w-[20%]">
            {Object.keys(filterOptions).map((filter) => (
              <div
                key={filter}
                className={`mb-2 cursor-pointer ${
                  selectedTab === filter ? 'font-medium' : ''
                }`}
                onClick={() => handleTabChange(filter)}
              >
                {filter}
              </div>
            ))}
          </div>
          <div className="flex-1 pl-4">
            <h4 className="font-medium mb-2">
              {selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1)}
            </h4>
            {renderFilterContent()}
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-2"
            onClick={handleApplyFilters}
          >
            Apply
          </button>
          <button
            className="px-4 py-2 text-gray-500 rounded-md hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            onClick={()=>{setFilter(false)}}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;