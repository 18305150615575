import React, { useEffect, useState } from 'react'
import backgroundnew from "../../assets/halfbg.png";
// import backgroundsm from "../assets/images/bgmain.webp";
import microsoftLogin from "../../assets/Microsoftlogin.svg";
import logo from "../../assets/Logo.svg";
import shadebg from "../../assets/Shadebg.svg";
// import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../utills/config';
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const { instance,inProgress,accounts } = useMsal();
   const checkIsLoggedIn = () => {
      const token = sessionStorage.getItem("auth_token");
      return !!token;
    };
    // const { instance } = useMsal();
    useEffect(() => {
      // Redirect to home if already logged in
      if (checkIsLoggedIn()) {
          navigate('/');
      }
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
          window.removeEventListener("resize", handleResize);
      };
  }, [navigate]);
      
  function handleLogin() {
    instance
      .loginPopup(loginRequest)
      .then((res) => {  
        console.log(res)
        sessionStorage.setItem("auth_token", res.accessToken);
        setTimeout(()=>{
          navigate("/");
        } , 1000)
      })
      .catch((e) => {
        console.error(e);
      });
    // navigate("/")
  }
  return (

    <div className='flex w-screen h-screen'>
      <img className='absolute left-0 top-[15%]' src={shadebg} alt='shade'/>
      <div className='absolute bottom-0 left-0 h-[12%] w-[2%] bg-[#169f9f]'></div>
      <div className='w-[65%] h-full'>
          <div
        className={`flex flex-col justify-center items-center md:items-start md:pl-[10vw] h-full w-full md:bg-opacity-100 bg-opacity-80 md:static md:transform-none md:h-full rounded-[8px] md:rounded-none ${
          windowWidth >= 768 ? "bg-transparent" : "bg-white"
        }`}
      >
        <div className="absolute top-8 md:top-8 md:left-18 ">
          <img className=" w-36" src={logo} alt="" />
        </div>
        <div className="md:pt-0 pt-16 p-1rem md:w-fit max-w-[45%] flex flex-col gap-1">
          <div className="font-semibold md:text-2xl text-2xl text-[#169f9f]">Welcome,</div>
          <div className=" md:text-xl text-xl font-semibold mt-2 text-[#12121b]">
          Greetings from TAMM
          </div>
          <div className=" md:text-sm text-sm font-normal mt-2 text-[#12121b]">
          TAMM is the Abu Dhabi Government's integrated platform for all government services, enabling you to access a wide range of services conveniently from a single source.
          </div>
          <div className="mt-8">
            <button
              className="items-center justify-center py-2 px-4 text-white border-1 outline-none w-[90%] border-[#00829B] rounded-lg bg-[#00829B] flex gap-2"
              type="button"
              onClick={() => handleLogin()}
            >
              <img src={microsoftLogin} alt="LOGO" className="h-6 w-6" />
              <span className="text-white font-roboto text-4 font-normal">
                Login with Microsoft
              </span>
            </button>
          </div>
        </div>
      </div>
      </div>
      <div className='w-[35%] h-full bg-[#169F9F] relative'>
        <img className='absolute -right-1/2 top-1/2 border-l-2  border-2 border-[#169f9f] transform -translate-x-1/2 -translate-y-1/2 max-w-[calc(100%_+_110px)]' src={backgroundnew} alt='bg'/>
      </div>
    </div>
  )
}

export default Login