
import React, { useEffect, useState, useCallback } from "react";
import TableComponent from "../../components/TableComponent";
import filters from "../../assets/Filter.svg";
import Header from "../../components/Header";
import FilterModal from "../../components/filterModal";
import { fetchHistoryData } from "../../api/api"; // Adjust the import path as needed
import { useChat } from "../../context/chatContext";
import { ACTIONS } from "../../context/chatReducer";
import toast from "react-hot-toast";

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("type");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { state, dispatch } = useChat();
  const { pagination } = state;
  const { currentPage, itemsPerPage, totalPages } = pagination;
  const [data, setData] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({
    type: [],
    // Index: [],
    Status: [],
    timestamp: {
      date:"", 
      startTime:"",
      endTime:""
    },
  });

  const filterOptions = {
    type: ["create", "update", "delete"],
    // Index: ["Lorem Ipsum", "Lorem isnon", "Lorem"],
    Status: ["completed", "rejected"],
    timestamp: ["date"],
  };


  const formatDate = (date) => {
    if (!date || date === 'all') return "all";
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };
  
  // Fetch data using the API function
  const loadHistoryData = async (start=null) => {
    const payload = {
      "start_index": start ? start-1 :(currentPage-1)* 10,
      "type": selectedFilters.type.length == 0 ? "all" :  selectedFilters.type[0],
      "start_time": (selectedFilters?.timestamp?.startTime !== '' && selectedFilters.timestamp.endTime !== '') ? selectedFilters.timestamp.startTime  : "all",
      "end_time": (selectedFilters.timestamp.endTime !== '' && selectedFilters?.timestamp?.startTime !=='' )? selectedFilters.timestamp.endTime : "all",
      "date":selectedFilters.timestamp.date == '' ?  "all" : formatDate(selectedFilters.timestamp.date),
      "status":  selectedFilters.Status.length == 0 ? "all" :  selectedFilters.Status[0]
      }
    try {
      setIsLoading(true);
      setError(null);
      const { data, totalLength } = await fetchHistoryData({ payload });
    
    // If you're using Redux dispatch
    dispatch({
      type: ACTIONS.SET_TABLE_DATA,
      payload: {
        data,
        totalLength
      }
    });
    
    // If you're using local state
    setData(data);
    setFilteredData(data);
    } catch (err) {
      setData([])
      setFilteredData([])
      toast.dismiss();
      toast.error(err.message)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadHistoryData();
  }, [currentPage]);
  useEffect(() => {
    loadHistoryData(1);

  }, [selectedFilters]);

  const handleApplyFilters = (filters) => {
    setSelectedFilters(filters);
    dispatch({ type: ACTIONS.SET_CURRENT_PAGE, payload: 1 });
    setFilter(false);
  };

  const handleSearch = useCallback(() => {
    setFilteredData(
      data.filter((item) => {
        const Index = item.Index || ''; // Handle cases where index_name might be undefined
        return Index.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
  }, [searchTerm, data]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const resetFilters = () => {
    setSelectedFilters({
      type: [],
      // Index: [],
      Status: [],
      timestamp: {
        date:"", 
        startTime:"",
        endTime:""
      },
    });
    setFilter(false)
    setFilteredData(data);
  };

  if (isLoading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <div className="text-red-600 text-center">
          <p className="text-xl font-semibold">Error loading data</p>
          <p className="text-sm mt-2">{error}</p>
          <button 
            onClick={loadHistoryData}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <Header />
      <div className="h-[88%]">
        <div className="h-[12%] w-full flex items-center justify-between p-2 px-6">
          <p className="text-[18px] font-semibold text-left text-[#12121B]">
            History Log :
          </p>
          <div className="flex items-center gap-4">
            <input
              type="text"
              className="border text-[12px] p-2 px-4 border-[#d9d9d9] outline-none w-[300px] h-full gap-0 rounded-lg overflow-auto"
              placeholder="Search Index Name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div>
              <img
                src={filters}
                alt="filter"
                onClick={() => setFilter(true)}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div className="h-[88%]">
          <TableComponent data={filteredData} />
        </div>
      </div>
      {filter && (
        <FilterModal
          setFilter={setFilter}
          filterOptions={filterOptions}
          onApplyFilters={handleApplyFilters}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          initialSelectedFilters={selectedFilters}
          onClearAll={resetFilters}
        />
      )}
    </div>
  );
};

export default Home;