import React from "react";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center h-full w-full">
      <div className="h-[250px] w-[350px] border-2 bg-[#c2c2c2] border-[#4d57a2] flex flex-col gap-2 justify-center items-center">
        <p className="text-[16px] text-[#a20000]">Page Not Found </p>
        <p className="text-[12px] text-[#0000a1]">
          Navigate to <a href="/" className="text-[#031221] cursor-pointer">Home</a>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
