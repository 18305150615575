import React, { createContext, useReducer, useContext } from 'react';
import { chatReducer } from './chatReducer';

const ChatContext = createContext();

const initialState = {
  chatId: null,
  messages: [],
  // Add table-related state
  tableData: [],
  filteredData: [],
  sortConfig: { key: null, direction: 'ascending' },
  selectedError: null,
  selectedObjectDetails: null,
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    totalPages: 1
  }
};

export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, initialState);

  return (
    <ChatContext.Provider value={{ state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);