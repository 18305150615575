import React, { useCallback, useEffect, useState } from 'react'
import Header from '../../components/Header'
import TableComponent from '../../components/TableComponent'
import filters from "../../assets/Filter.svg";
import { fetchIndexData } from '../../api/api';
import FilterModal from '../../components/filterModal';
import { useChat } from '../../context/chatContext';
import { ACTIONS } from '../../context/chatReducer';
import toast from 'react-hot-toast';


const AiSearchPage = () => {
  const [filter, setFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("type");
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const { state, dispatch } = useChat();
  const { pagination } = state;
  const { currentPage } = pagination;
  const [selectedFilters, setSelectedFilters] = useState({
    type: [],
    Index: [],
    Status: [],
    logs: [],
  });

  const filterOptions = {
    type: ["Create", "Update", "Delete"],
    Index: ["Lorem Ipsum", "Lorem isnon", "Lorem"],
  };

  const handleApplyFilters = (filters) => {
    setSelectedFilters(filters);
    setFilteredData(
      data.filter((item) =>
        (filters.type.length === 0 || filters.type.includes(item.Type)) &&
        (filters.Index.length === 0 || filters.Index.some((Index) => item.Index.includes(Index)))
      )
    );
    setFilter(false);
  };

  // Fetch data using the API function
  const loadIndexData = async (start=null) => {
    const payload = {
      "start_index": start ? start-1 :(currentPage-1)* 10,
      }
    try {
      setIsLoading(true);
      setError(null);
      const {data, totalLength} = await fetchIndexData({payload});
      dispatch({
        type: ACTIONS.SET_TABLE_DATA,
        payload: {
          data,
          totalLength
        }
      });
      setData(data);
      setFilteredData(data);
    } catch (err) {
      // setError(err.message);
      setData([])
      setFilteredData([])
      setData([])
      setFilteredData([])
      toast.dismiss();
      toast.error(err.message)
      console.error('Failed to fetch history data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadIndexData();
  }, [currentPage]);
  const handleSearch = useCallback(() => {
    setFilteredData(
      data.filter((item) => {
        const Index = item.Index || ''; // Handle cases where index_name might be undefined
        return Index.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
  }, [searchTerm, data]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);


  const resetFilters = () => {
    setSelectedFilters({
      type: [],
      Index: [],
      Status: [],
      logs: [],
    });
    setFilteredData(data);
  };
  if (isLoading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <div className="text-red-600 text-center">
          <p className="text-xl font-semibold">Error loading data</p>
          <p className="text-sm mt-2">{error}</p>
          <button 
            onClick={loadIndexData}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className='h-full w-full'>
        <Header/>
        <div className='h-[88%]'>
        <div className="h-[12%] w-full flex items-center justify-between p-2 px-6">
        <p className="text-[18px] font-semibold text-left text-[#12121B]">
         Index Info:
        </p>
        <div className="flex items-center gap-4">
          <input
            type="text"
            className="border text-[12px] p-2 px-4 border-[#d9d9d9] outline-none w-[300px] h-full gap-0 rounded-lg overflow-auto"
            placeholder="Search Index Name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} 
          />
          <div>
          </div>
        </div>
      </div>
      <div className="h-[88%]">
      <TableComponent data={filteredData} />   
     </div>
    </div>
    {filter && (
        <FilterModal
          setFilter={(isOpen) => {
            if (!isOpen) resetFilters();
            setFilter(isOpen);
          }}
          filterOptions={filterOptions}
          onApplyFilters={handleApplyFilters}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          initialSelectedFilters={selectedFilters}
        />
      )}
    </div>
  )
}

export default AiSearchPage 