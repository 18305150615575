// chatReducer.js
const initialState = {
  chatId: null,
  messages: [],
  tableData: [],
  filteredData: [],
  sortConfig: { key: null, direction: 'ascending' },
  selectedError: null,
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    totalPages: 1
  }
};

export const ACTIONS = {
  SET_CHAT_ID: 'SET_CHAT_ID',
  ADD_MESSAGE: 'ADD_MESSAGE',
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_SORT_CONFIG: 'SET_SORT_CONFIG',
  SET_SELECTED_ERROR: 'SET_SELECTED_ERROR',
  SORT_TABLE_DATA: 'SORT_TABLE_DATA',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_SELECTED_OBJECT_DETAILS: 'SET_SELECTED_OBJECT_DETAILS',
};

export const chatReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_CHAT_ID:
      return { ...state, chatId: action.payload };

    case ACTIONS.ADD_MESSAGE:
      return { ...state, messages: [...state.messages, action.payload] };

      case ACTIONS.SET_TABLE_DATA:
        return {
          ...state,
          tableData: action.payload.data,
          filteredData: action.payload.data,
          pagination: {
            ...state.pagination,
            totalPages: Math.ceil(action.payload.totalLength / state.pagination.itemsPerPage)
          }
        };

    case ACTIONS.SET_SORT_CONFIG:
      return {
        ...state,
        sortConfig: action.payload
      };

    case ACTIONS.SET_SELECTED_ERROR:
      return {
        ...state,
        selectedError: action.payload
      };

    case ACTIONS.SORT_TABLE_DATA:
      const { key, direction } = action.payload;
      const sortedData = [...state.filteredData].sort((a, b) => {
        const aValue = a[key];
        const bValue = b[key];

        if (key === "Timestamp") {
          const dateA = new Date(aValue);
          const dateB = new Date(bValue);
          return direction === "ascending" ? dateA - dateB : dateB - dateA;
        } else {
          if (aValue < bValue) {
            return direction === "ascending" ? -1 : 1;
          }
          if (aValue > bValue) {
            return direction === "ascending" ? 1 : -1;
          }
        }
        return 0;
      });

      return {
        ...state,
        filteredData: sortedData,
        sortConfig: { key, direction }
      };

    case ACTIONS.SET_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload
        }
      };
      case ACTIONS.SET_SELECTED_OBJECT_DETAILS:
        return {
          ...state,
          selectedObjectDetails: action.payload,
        };
      case ACTIONS.CLEAR_SELECTED_OBJECT_DETAILS:
        return {
          ...state,
          selectedObjectDetails: null,
        };
    default:
      return state;
  }
};