import React, { useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import DeleteConfirm from '../assets/DeleteConfirm.svg'
import { DeleteIndexData } from '../api/api';
import toast from 'react-hot-toast';

const DeleteModal = ({SetShowdeleteModal , ShowDeleteModal, loadHistoryData}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const deleteIndex= async () => {
        toast.loading("Deleting Index")
            const payload = {
              "index_name": ShowDeleteModal,
              }
            try {
              setIsLoading(true);
              setError(null);
              const historyData = await DeleteIndexData({payload});
              loadHistoryData();
              SetShowdeleteModal(false)
              toast.dismiss();
              toast.success("Deleted successfull")
            } catch (err) {
                toast.dismiss();
            toast.error("Something went wrong")
              console.error('Failed to fetch history data:', err);
            } finally {
              setIsLoading(false);
            }
          };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div
            className="bg-white p-6 rounded-lg max-h-[58vh] max-w-[30vw] overflow-auto"
          >
            <div className="flex items-center justify-between mb-4">
                <h2>Delete Index</h2>
              <p className="cursor-pointer" onClick={() => SetShowdeleteModal(null)}>
                <IoMdClose />
              </p>
            </div>
              <div className='flex flex-col gap-4 justify-center items-center'>
                <div className='flex flex-col gap-1 justify-center items-center w-[80%]'>
                <img  height={68} width={68} alt='warning' src={DeleteConfirm} />
                <h3 className='text-[#242424] text-base font-normal text-left'> Are You Sure?</h3>
            <p className="text-[#777777] text-sm font-normal text-center p-2">Click on “yes, delete” to remove the selected index.</p>
            </div>
            <div className='flex justify-between gap-4 w-full'>
                <button onClick={()=>{deleteIndex()}} className='px-6 py-2 bg-[#169f9f] text-white rounded w-[50%]'>Yes, delete</button>
                <button onClick={()=>{SetShowdeleteModal(false)}} className='px-6 py-2 border-2 border-[9B9B9B] rounded w-[50%]'>Cancel</button>
            </div>
              </div>
          </div>
        </div>
  )
}

export default DeleteModal