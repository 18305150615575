// DetailsPage.js
import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import TableComponent from "../../components/TableComponent";
import Header from "../../components/Header";
import back from "../../assets/back.svg";
import { fetchMapData } from "../../api/api";
import { ACTIONS } from "../../context/chatReducer";
import { useChat } from "../../context/chatContext";
import toast from "react-hot-toast";

const DetailsPage = () => {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("Mapping");
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [indexData, setIndexData] = useState({"data":[]});
  const navigate = useNavigate();
  const { state, dispatch } = useChat();

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const loadMappingData = async () => {
    const payload = {
        "id": id,
        }
    try {
      setIsLoading(true);
      setError(null);
      const historyData = await fetchMapData({payload});
      setIndexData(historyData);
      setData( Object.entries(historyData.data[0].mapping).map(([name, tabledata]) => ({
        name,
        tabledata
    })));
      setFilteredData( Object.entries(historyData.data[0].mapping).map(([name, tabledata]) => ({
        name,
        tabledata
    })));
    dispatch({
        type: ACTIONS.SET_TABLE_DATA,
        payload: {
          historyData,
          totalLength: 1
        }
      });
    } catch (err) {
    //   setError(err.message);
    setData([])
    setFilteredData([])
    toast.dismiss();
    toast.error(err.message)
      console.error('Failed to fetch history data:', err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
     loadMappingData();
  }, []);

  useEffect(() => {
    if (indexData.data.length > 0) {  // Safe check for indexData structure
      if (activeTab === "Mapping") {
        const mappingData = Object.entries(indexData.data[0].mapping).map(([name, tabledata]) => ({
          name,
          tabledata
        }));
        setData(mappingData);
        setFilteredData(mappingData);
      } 
      else if (activeTab === "Selected _columns") {
        const selectedColumns = indexData.data[0].selected_columns.map(name => ({
          name
        }));
        setData(selectedColumns);
        setFilteredData(selectedColumns);
      } 
      else if (activeTab === "Schema") {
        const transformSchemaToData = (schema) => {
            return schema.map((field) => {
              // Base transformation for each field
              const transformedField = {
                Field_name: field.name,
                Type: field.type,
                Key: field.key || false,
                Retrievable: field.retrievable || false,
                Searchable: field.searchable || false,
                Facetable: field.facetable || false,
                Sortable: field.sortable || false,
                Stored: field.stored || false,
                Filterable: field.filterable || false, // Placeholder for fields without a corresponding property
                subItems: []
              };
          
              // If the field is a complex type, transform each nested field
              if (field.fields) {
                transformedField.subItems = field.fields.map((subField) => ({
                  Field_name: subField.name,
                  Type: subField.type,
                  Key: false, // Nested fields aren't keys by default
                  Retrievable: subField.retrievable,
                  Searchable: subField.searchable || false,
                  Facetable: subField.facetable || false,
                  Sortable: subField.sortable || false,
                  Stored: subField.stored || false,
                  Filterable: subField.filterable || false // Placeholder
                }));
              }
          
              return transformedField;
            });
          };
          
          // Usage
          const data1 = transformSchemaToData(indexData.data[0].schema);  
        setData(data1);
        setFilteredData(data1);
      }
    }
  }, [activeTab, indexData]);
  const handleSearch = useCallback(() => {
    setFilteredData(
      data.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    );
  }, [searchTerm, data]);
  useEffect(() => {
    handleSearch();
  }, [handleSearch]);
  return (
    <div className="h-full w-full">
      <Header />
      <div className="h-[88%]">
      <div className="h-[12%] w-full flex items-center justify-between p-2 px-6">
        <p className="text-[18px] font-semibold text-left text-[#12121B]">
          Index Info :
        </p>
        <div className="flex items-center gap-4">
          <input
            type="text"
            className="border text-[12px] p-2 px-4 border-[#d9d9d9] outline-none w-[300px] h-full gap-0 rounded-lg overflow-auto"
            placeholder="Search Index Name...'"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update search term on change
          />
          <div>
          </div>
        </div>
      </div>
      <div className="w-full h-[8%] flex items-start gap-2 px-6 mt-2">
        <div onClick={()=>{navigate("/AiSearch")}} className="mr-4 cursor-pointer"><img src={back} alt="back"/></div>
       <div className="flex items-center flex-wrap gap-2 justify-between"> <div className=" font-semibold"> ID :  <span className="text-[14px] font-normal">  {indexData.data.length > 0 ? indexData.data[0].id : id} </span> </div>
        <div className=" font-semibold">Table: <span className="text-[14px] font-normal">  {indexData.data.length > 0 ? indexData.data[0].table_name : "ok"}</span></div>
        <div className=" font-semibold">Index: <span className="text-[14px] font-normal">{indexData.data.length > 0 ?  indexData.data[0].index_name  : "ok" } </span> </div>
        <div className=" font-semibold">Target Column: <span className="text-[14px] font-normal"> {indexData.data.length > 0 ?  indexData.data[0].target_column  : "ok"} </span> </div>
        <div className=" font-semibold">Target Value: <span className="text-[14px] font-normal"> {indexData.data.length > 0 ?  indexData.data[0].target_value  : "ok" } </span></div>
      </div>
      </div>
      <div className="flex space-x-4 py-4 h-[16%] w-fit ml-6 2xl:h-[12%]">
        <button
          onClick={() => handleTabClick("Mapping")}
          className={`px-4 py-2 font-medium text-gray-700 ${
            activeTab === "Mapping"
              ? "border-b-4 border-[#006A6A] text-[#006A6A]"
              : "hover:border-gray-400"
          }`}
        >
          Mapping
        </button>
        <button
          onClick={() => handleTabClick("Schema")}
          className={`px-4 py-2 font-medium text-gray-700 ${
            activeTab === "Schema"
              ? "border-b-4 border-[#006A6A] text-[#006A6A]"
              : "hover:border-gray-400"
          }`}
        >
          Schema
        </button>
        <button
          onClick={() => handleTabClick("Selected _columns")}
          className={`px-4 py-2 font-medium text-gray-700 ${
            activeTab === "Selected _columns"
              ? "border-b-4 border-[#006A6A] text-[#006A6A]"
              : "hover:border-gray-400"
          }`}
        >
          Selected _columns
        </button>
      </div>
      <div className="p-4 h-[62%]">
        {activeTab === "Mapping" && (
          <div className="h-full">
            <TableComponent data={filteredData} />
          </div>
        )}
        {activeTab === "Schema" && (
          <div className="h-full schematable">
            <TableComponent data={filteredData} hasAccordion={true} />
          </div>
        )}
        {activeTab === "Selected _columns" && (
          <div className="h-full">
            <TableComponent data={filteredData} />{" "}
          </div>
        )}
      </div>
      </div>
    </div>
  );
};

export default DetailsPage;
